import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosService } from 'api/axiosService';
import { components } from '../openapi-types';

type UserResponse = components['schemas']['ListUsersResponse'];
export type User = UserResponse['result'];

export const useGetUsers = () => {
  return useQuery({
    queryKey: ['get-users'],

    queryFn: async () => {
      const result = await AxiosService.get<UserResponse>(`users`);
      return result;
    },

    staleTime: 5000,
    gcTime: 1000,
  });
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['invite-user'],

    mutationFn: async (data: components['schemas']['CreateUserRequest']) => {
      const result = await AxiosService.post<UserResponse>(`users`, data);
      return result;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-users'],
      });
    },
  });
};

export const useUserAccept = () => {
  return useMutation({
    mutationKey: ['user-accept'],

    mutationFn: async (token: string) => {
      const result = await AxiosService.get<components['schemas']['SuccessResponse']>(
        `users/accept?token=${token}`
      );
      return result;
    },
  });
};

export const useDeleteUser = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`delete-user-${userId}`],

    mutationFn: async () => {
      const result = await AxiosService.delete<components['schemas']['SuccessResponse']>(
        `users/${userId}`
      );
      return result;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-users'],
      });
    },
  });
};

export const useReinviteUser = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [`reinvite-user-${userId}`],

    mutationFn: async () => {
      const result = await AxiosService.post<components['schemas']['SuccessResponse']>(
        `users/${userId}/reinvite`
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-users'] });
    },
  });
};

export const useUserChangeTwoAuth = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['change-2fa-user'],
    mutationFn: async ({ twofa }: { twofa: boolean }) => {
      const result = await AxiosService.patch<components['schemas']['UpdateUserRequest']>(
        `users/${userId}`,
        {
          twofactor_required: twofa,
        }
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-users'],
      });
    },
  });
};

export const useMeChangeTwoAuth = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['change-2fa-my-user'],
    mutationFn: async ({ twofa }: { twofa: boolean }) => {
      const result = await AxiosService.patch<components['schemas']['GetAccountMeResponse']>(
        '/account/me',
        {
          twofactor_enabled: twofa,
        }
      );
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile'],
      });
    },
  });
};
